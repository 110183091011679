<template>
  <div class="auth-center">
    <div>
      <Header />
      <ResponsiveAuth>
        <Login />
      </ResponsiveAuth>
    </div>
  </div>
</template>

<script>
import ResponsiveAuth from "@/components/4laps/ResponsiveAuth";
import Login from "@/components/4laps/Login";
import Header from "@/components/4laps/Header";

export default {
  name: "Home",
  components: {
    ResponsiveAuth,
    Login,
    Header,
  },
};
</script>

<style scoped></style>
